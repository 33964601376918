body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  display: flex;
}

.App {
  text-align: center;
  width: min(100vh, 100vw);
  height: min(100vh, 100vw);
  max-width: 3000px;
  max-height: 3000px;
  margin: auto auto;
}

.App canvas {
  width: 100%;
  height: 100%;
}

.App-colors {
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.App-colors__item {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 2px;
  margin-top: 3px;
  border: 3px solid #3227b9;
  outline: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.App-colors__item--active {
  border: 3px solid #fe4c60;
}


.App-dress-list {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  list-style: none;
  padding: 0;
  margin: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.App-dress__item {
  text-align: left;
  padding: 3px;
  color: #3227b9;
  font-weight: bold;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.App-dress__item--active {
  color: #fe4c60;
}
